<template>
	<div class="page-header h-100 flex align-center">
		<i class="el-icon-s-fold font-lger text-black" @click="isC" v-show="block"></i>
		<i class="el-icon-s-unfold font-lger text-black" @click="isC" v-show="toggle"></i>
		<span class="font text-black ml-2">您好，欢迎来到{{config.shop_name || '管理系统平台'}}！</span>
		<div class="ml-auto flex align-center">
		  <div class="flex align-center ml-4">
			<el-dropdown trigger="click">
			  <div class="flex align-center text-black">
				<el-avatar :size="28" :src="avatarUrl"></el-avatar><span class="font ml-1">{{name || '系统用户'}}</span>
			  </div>
			  <el-dropdown-menu slot="dropdown">
				<div @click="logOut"><el-dropdown-item><i class="el-icon-user-solid font-md"></i>退出登录</el-dropdown-item></div>
			  </el-dropdown-menu>
			</el-dropdown>
			<img src="@/assets/images/exit.png" alt="退出" style="width:20x;height:20px;margin-left:10px;cursor: pointer;" @click="logOut">
		  </div>
    </div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	export default {
		name: 'Header',
		data () {
			return {
				// 头像
				avatarUrl:this.$cookies.get('avatar') && this.$cookies.get('avatar') != 'null' ? this.$cookies.get('avatar') : require("@/assets/images/logo.jpg"),
				name:this.$cookies.get('userName'),
				toggle: false,//第二个图标默认隐藏
				block: true,//默认显示第一个图标
			}
		},
		components: {},
		computed: {
		   ...mapGetters([
		         'config'
		   ])
		},
		methods:{
		  logOut(){
			  this.$cookies.remove("token");
			  this.$router.push("/login");
		  },
		  isC() {
		  	  this.$emit('changeCollapse')
		      this.toggle = !this.toggle;
		      this.block = !this.block;
		  },
		},
	}
</script>

<style>

</style>