<template>
  <div class="nav_menu">
    <template v-for="menuData in menuDatas">
      <!-- 不分组 -->
      <!-- 不分组只有一级菜单 -->
      <el-menu-item @click="menuClick(menuData.id)" v-if="menuData&&menuData.sub==undefined || menuData&&menuData.sub===null || menuData&&menuData.sub.length===0" :key="menuData.path" :index="menuData.path" :route="menuData.path">
        <!--图标-->
		<svg-icon :icon-class="menuData.icon ? menuData.icon : ''" style="margin-right: 12px;width: 18px;height: 18px;color: #fff;" />
        <!--标题-->
        <span slot="title">{{menuData.title}}</span>
      </el-menu-item>

      <!--不分组有多级菜单-->
     <el-submenu v-if="menuData&&menuData.sub&&menuData.sub.length!=0" :key="menuData.path" :index="menuData.path">
        <div slot="title">
		  <svg-icon :icon-class="menuData.icon ? menuData.icon : ''" style="margin-right: 12px;width: 18px;height: 18px;color: #fff;" />
          <span>{{menuData.title}}</span>
        </div>
        <!-- 递归组件，把遍历的值传回子组件，完成递归调用 -->
       <Menu class="multiple_menu" :menuDatas="menuData.sub"></Menu>
      </el-submenu>

      <!-- /不分组 -->
    </template>
  </div>
</template>

<script>
  export default {
    name: 'Menu', //使用递归组件必须要有
    props: ['menuDatas'], // 传入子组件的数据
    data() {
      return {
		  
      }
    },
    mounted(){
      this.menuClick(this.$cookies.get("back_menu_id"));
    },
    methods: {
      // 获取该菜单列表下的所有操作按钮
      menuClick(id){
        this.$cookies.set('back_menu_id', id);
      },
    },
  }
</script>

<style>

</style>
